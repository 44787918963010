import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Content from "../../components/content/Content";
import shared from "../../styles/shared.module.css";
import classNames from "classnames";
import styles from "./Landing.module.css";
import DemoActivity from "./components/DemoActivity";
import DemoCity from "./components/DemoCity";
import AuthBoxContent from "../../components/auth/AuthBoxContent";
import { Button } from "@mui/material";
import Footer from "./components/Footer";
import { DemoDashboardNeighbourhoods } from "./components/DemoNeighbourhoods";
import { IonContent } from "@ionic/react";
import AppAvailableNotification from "../../components/appavailablenotification/AppAvailableNotification";
import DemoNeighbourhood from "./components/DemoNeighbourhood";
import { UserNeighbourhoodType } from "cp-server";
type DispatchProps = {};
type StateProps = {};
type PropsType = DispatchProps & StateProps;

type StateType = {};
const citiesData: any = [
  {
    area_id: "2d8fece7-9669-46a8-984a-6bb622ae7016",
    name: "Glasgow\n",
    polygon: "",
    id_external: 1,
    bounding_box: {
      type: "Polygon",
      coordinates: [
        [
          [-4.3932006, 55.7812945],
          [-4.3932006, 55.9296388],
          [-4.0717169, 55.9296388],
          [-4.0717169, 55.7812945],
          [-4.3932006, 55.7812945],
        ],
      ],
    },
    total_roads_distance: "2000947.28724267",
    total_off_roads_distance: "332763.789881969",
    total_landmarks: "111",
  },
  {
    area_id: "00ced30d-72d4-4e2e-9ed4-8dda0b211f77",
    name: "East Lothian",
    polygon: "",

    id_external: 6,
    bounding_box: {
      type: "Polygon",
      coordinates: [
        [
          [-3.0892802, 55.8173183],
          [-3.0892802, 56.0679535],
          [-2.3632807, 56.0679535],
          [-2.3632807, 55.8173183],
          [-3.0892802, 55.8173183],
        ],
      ],
    },
    total_roads_distance: "1145592.55506276",
    total_off_roads_distance: "454763.70761224",
    total_landmarks: "143",
  },
  {
    area_id: "6f4a45c4-d6aa-4d59-bdbc-1c8a80ea2f34",
    name: "Dundee",
    polygon: "",
    id_external: 5,
    bounding_box: {
      type: "Polygon",
      coordinates: [
        [
          [-3.0980246, 56.443579],
          [-3.0980246, 56.5040239],
          [-2.8322022, 56.5040239],
          [-2.8322022, 56.443579],
          [-3.0980246, 56.443579],
        ],
      ],
    },
    total_roads_distance: "618293.493959691",
    total_off_roads_distance: "61129.1679559422",
    total_landmarks: "54",
  },
];

const demoNeighbourhoods: Array<UserNeighbourhoodType & { image: string }> = [
  {
    image: require("../../assets/landing/neighbourhood_1.png"),
    user_city_id: "403f0df3-e2f4-47af-9ecb-2d6fafd3ae9e",
    user_id: "94320bb8-4269-49cc-b4b6-92c086486710",
    landmarks_visited: "6",
    user_neighbourhood_id: "997c8568-71de-4576-85cd-8feb2ca82553",
    percent_complete: "92.3",
    percent_covered_month: "0.0",
    percent_covered_year: "0.00",

    activities_count: "152",
    total_landmarks: 6,

    neighbourhood_id: "130c2822-80f0-432e-a6e2-775cea561aff",
    name: "New Town West",
    area_id: "db097d54-ff15-4ef0-9ed0-a7eb0fc483a5",
    bounding_box: {
      type: "Polygon",
      coordinates: [],
    },
  },
  {
    image: require("../../assets/landing/neighbourhood_2.png"),
    user_city_id: "403f0df3-e2f4-47af-9ecb-2d6fafd3ae9e",
    user_id: "94320bb8-4269-49cc-b4b6-92c086486710",
    landmarks_visited: "52",
    user_neighbourhood_id: "9a36f2fc-0b26-45f3-ab3d-a84f49a456b3",
    percent_complete: "88.9",
    percent_covered_month: "0.0",
    percent_covered_year: "0.00",
    activities_count: "68",
    neighbourhood_id: "344aa1b8-4134-4a1b-ba6d-0678ba61cfa6",
    name: "Old Town",
    area_id: "db097d54-ff15-4ef0-9ed0-a7eb0fc483a5",
    total_landmarks: 74,
    bounding_box: {
      type: "Polygon",
      coordinates: [],
    },
  },
  {
    image: require("../../assets/landing/neighbourhood_3.png"),
    user_city_id: "403f0df3-e2f4-47af-9ecb-2d6fafd3ae9e",
    user_id: "94320bb8-4269-49cc-b4b6-92c086486710",
    landmarks_visited: "11",
    user_neighbourhood_id: "03ce7ac3-f847-4d46-8d40-2077c82b16d1",
    percent_complete: "90.0",
    percent_covered_month: "0.0",
    percent_covered_year: "0.00",

    activities_count: "232",
    neighbourhood_id: "72b2c893-0067-4e10-970a-b0e9d6ac4551",
    name: "Leith Docks",
    total_landmarks: 13,
    area_id: "db097d54-ff15-4ef0-9ed0-a7eb0fc483a5",
    bounding_box: {
      type: "Polygon",
      coordinates: [],
    },
  },
];

// @ts-ignore
const demoActivityData: any = {
  landmarks: [
    {
      landmark_id: "",
      area_id: "",
      name: "Masons' Pillars",
      type: "monument",
      way: {
        type: "Point",
        coordinates: [-3.2003081, 55.9420153],
      },
      way_point: {
        type: "Point",
        coordinates: [-3.2003081, 55.9420153],
      },
      osm_id: "2265831081",
      activity_id: "",
      user_id: "",
    },
    {
      landmark_id: "",
      area_id: "",
      name: "John White memorial plaque",
      type: "memorial",
      way: {
        type: "Point",
        coordinates: [-3.1916148, 55.9426519],
      },
      way_point: {
        type: "Point",
        coordinates: [-3.1916148, 55.9426519],
      },
      osm_id: "6718988629",
      activity_id: "",
      user_id: "",
    },
    {
      landmark_id: "",
      area_id: "",
      name: "Meadows Mural",
      type: "artwork",
      way: {
        type: "Point",
        coordinates: [-3.1909755, 55.9426517],
      },
      way_point: {
        type: "Point",
        coordinates: [-3.1909755, 55.9426517],
      },
      osm_id: "6410405812",
      activity_id: "",
      user_id: "",
    },
    {
      landmark_id: "",
      area_id: "",
      name: "Nether Liberton Dovecot",
      type: "monument",
      way: {
        type: "Polygon",
        coordinates: [
          [
            [-3.1646606, 55.9229883],
            [-3.1645999, 55.9229426],
            [-3.1644631, 55.9229997],
            [-3.1645238, 55.9230454],
            [-3.1646606, 55.9229883],
          ],
        ],
      },
      way_point: {
        type: "Point",
        coordinates: [-3.16456185, 55.922994],
      },
      osm_id: "572722814",
      activity_id: "",
      user_id: "",
    },
    {
      landmark_id: "",
      area_id: "",
      name: "Old Police Call Box",
      type: "monument",
      way: {
        type: "Polygon",
        coordinates: [
          [
            [-3.183211, 55.9411098],
            [-3.1832007, 55.9410994],
            [-3.1831849, 55.9411043],
            [-3.1831952, 55.9411147],
            [-3.183211, 55.9411098],
          ],
        ],
      },
      way_point: {
        type: "Point",
        coordinates: [-3.18319795, 55.94110705],
      },
      osm_id: "772983994",
      activity_id: "79080b23-1270-4ec4-9a1e-65011af89a9d",
      user_id: "5c051110-ea2c-4474-b1fc-cf16a4d517fc",
    },
    {
      landmark_id: "37f0d9b2-cb03-44d7-ad7a-5fd6fd7783e8",
      area_id: "4a96f4fd-fb10-4c95-bb11-92abc55dcc27",
      name: "Hays Chair",
      type: "artwork",
      way: {
        type: "Point",
        coordinates: [-3.1225496, 55.934064],
      },
      way_point: {
        type: "Point",
        coordinates: [-3.1225496, 55.934064],
      },
      osm_id: "313714601",
      activity_id: "79080b23-1270-4ec4-9a1e-65011af89a9d",
      user_id: "5c051110-ea2c-4474-b1fc-cf16a4d517fc",
    },
    {
      landmark_id: "1273c0a1-a2d5-43ef-a745-a308c81784f4",
      area_id: "4a96f4fd-fb10-4c95-bb11-92abc55dcc27",
      name: "Masons' Pillars",
      type: "monument",
      way: {
        type: "Point",
        coordinates: [-3.2005583, 55.9419278],
      },
      way_point: {
        type: "Point",
        coordinates: [-3.2005583, 55.9419278],
      },
      osm_id: "6150533301",
      activity_id: "79080b23-1270-4ec4-9a1e-65011af89a9d",
      user_id: "5c051110-ea2c-4474-b1fc-cf16a4d517fc",
    },
    {
      landmark_id: "0f20b009-04ae-46e0-b93c-93238a84b0c1",
      area_id: "4a96f4fd-fb10-4c95-bb11-92abc55dcc27",
      name: "Old Police Call Box",
      type: "monument",
      way: {
        type: "Polygon",
        coordinates: [
          [
            [-3.149582, 55.9550837],
            [-3.1495818, 55.9550707],
            [-3.1495458, 55.9550697],
            [-3.1495459, 55.9550838],
            [-3.149582, 55.9550837],
          ],
        ],
      },
      way_point: {
        type: "Point",
        coordinates: [-3.14956363084772, 55.955076975466],
      },
      osm_id: "473327007",
      activity_id: "79080b23-1270-4ec4-9a1e-65011af89a9d",
      user_id: "5c051110-ea2c-4474-b1fc-cf16a4d517fc",
    },
    {
      landmark_id: "064e837b-5f0f-4dff-b07e-f501992d0ed7",
      area_id: "4a96f4fd-fb10-4c95-bb11-92abc55dcc27",
      name: "Muschat's cairn",
      type: "monument",
      way: {
        type: "Polygon",
        coordinates: [
          [
            [-3.1583466, 55.9543929],
            [-3.1583257, 55.9543849],
            [-3.1582764, 55.9544109],
            [-3.158283, 55.9544237],
            [-3.1583323, 55.9544162],
            [-3.1583466, 55.9543929],
          ],
        ],
      },
      way_point: {
        type: "Point",
        coordinates: [-3.15831293022715, 55.9544057331077],
      },
      osm_id: "460962807",
      activity_id: "79080b23-1270-4ec4-9a1e-65011af89a9d",
      user_id: "5c051110-ea2c-4474-b1fc-cf16a4d517fc",
    },
    {
      landmark_id: "9831aded-750d-4032-8cb4-986dbd868351",
      area_id: "4a96f4fd-fb10-4c95-bb11-92abc55dcc27",
      name: "Old Police Call Box",
      type: "monument",
      way: {
        type: "Polygon",
        coordinates: [
          [
            [-3.135556, 55.9604023],
            [-3.1355522, 55.9603893],
            [-3.1355217, 55.9603921],
            [-3.1355255, 55.9604052],
            [-3.135556, 55.9604023],
          ],
        ],
      },
      way_point: {
        type: "Point",
        coordinates: [-3.13553883133385, 55.9603972277825],
      },
      osm_id: "463484450",
      activity_id: "79080b23-1270-4ec4-9a1e-65011af89a9d",
      user_id: "5c051110-ea2c-4474-b1fc-cf16a4d517fc",
    },
  ],
  id: "",
  user_id: "",
  start_time: "2021-03-18T13:28:27.000Z",
  type: "Ride",
  start_latlng: "0101000020E610000029266F8099EF08C0BF7D1D3867F64B40",
  end_latlng: "0101000020E61000009AB0FD648CEF08C0CAC342AD69F64B40",
  moving_time: 6760,
  vendor_activity_id: "4967884068",
  vendor: "strava",
  name: "Afternoon Ride",
  elevation_gain: "239.3",
  distance: "26799.4",
  percent_new: "23.16",
  bounding_box: {
    type: "Polygon",
    coordinates: [
      [
        [-3.219446, 55.910014],
        [-3.219446, 55.969416],
        [-3.090044, 55.969416],
        [-3.090044, 55.910014],
        [-3.219446, 55.910014],
      ],
    ],
  },
};
class Landing extends Component<PropsType, StateType> {
  renderAuthBox() {
    return (
      <div className={styles.authBox}>
        <AuthBoxContent />
      </div>
    );
  }

  render() {
    return (
      <>
        <Header logoOnly showAuth />
        <IonContent>
          <div className={styles.logoMobile}>
            <img
              src={require("../../assets/cp_logo.png")}
              width={200}
              style={{ marginTop: 16 }}
            />
          </div>
          <AppAvailableNotification />
          <Content maxWidth={1000}>
            <div className={styles.topHeaderContainer}>
              <div>
                <span className={classNames(shared.heading2, styles.header)}>
                  How much of your city
                  <br /> have you <b>really</b> seen?
                </span>
              </div>
              <div className={styles.joinButtonMobile}>
                <Button
                  style={{ backgroundColor: "var(--orange-1)" }}
                  href={"/auth"}
                >
                  <span style={{ color: "#eeeeee" }}>Join</span>
                </Button>
              </div>
            </div>
            <span className={classNames(shared.heading4, styles.subHeader)}>
              Connect with Strava* to see detailed analysis of the streets,
              trails and landmarks that you have seen in your city or area
              <br />
              <br />
              Can you <b>“paint”</b> every street in your area?
            </span>
            <div className={styles.firstImageAndAuthContainer}>
              <picture>
                <source
                  srcSet={require("../../assets/landing/landing_image_2_mobile.webp")}
                  media="(max-width: 600px)"
                />
                <img
                  src={require("../../assets/landing/landing_desktop_1.webp")}
                  alt=""
                />
              </picture>

              <div className={styles.authBoxDesktop}>
                {this.renderAuthBox()}
              </div>
            </div>
            <span
              className={classNames(styles.heading4, styles.subHeader)}
              style={{ marginTop: 24 }}
            >
              See how much <b>new ground</b> you cover in each activity
            </span>
            <div className={styles.activityList}>
              <DemoActivity data={demoActivityData} />
            </div>
            <span className={classNames(shared.heading2, styles.header)}>
              Can you visit all the <b>landmarks</b> in your city?
            </span>
            <span className={classNames(shared.heading4, styles.subHeader)}>
              Landmarks include statues, monuments, public artwork, viewpoints,
              castles, ruins, mountain peaks and anything else that could make
              your activity just that little bit <b>more interesting</b>
            </span>
            <picture>
              <source
                srcSet={require("../../assets/landing/landing_image_4_mobile.webp")}
                media="(max-width: 600px)"
              />
              <img
                src={require("../../assets/landing/landing_image_4.webp")}
                className={styles.imageWithShadow}
              />
            </picture>

            <div>
              <br />
              <span className={classNames(shared.heading2, styles.header)}>
                Complete your city <b>neighbourhood by neighbourhood</b>
              </span>
              <DemoDashboardNeighbourhoods />
            </div>
            <div>
              <br />
              <div>
                <span className={classNames(shared.heading4, styles.subHeader)}>
                  Stats and maps for <b>each neighbourhood</b>
                </span>
                <div className={styles.cityList}>
                  {demoNeighbourhoods.map((un) => (
                    <DemoNeighbourhood userNeighbourhood={un} />
                  ))}
                </div>
              </div>
            </div>

            {/* Leaderboards*/}
            <span className={classNames(shared.heading2, styles.header)}>
              Get <b>competitive</b> with monthly and all time{" "}
              <b>leaderboards</b>
            </span>
            <div className={styles.leaderboardContainer}>
              <img
                src={require("../../assets/landing/landing_leaderboard.webp")}
                style={{ maxWidth: 320 }}
                className={styles.imgShadow}
              />
              <img
                src={require("../../assets/landing/landing_leaderboard_2.webp")}
                style={{ maxWidth: 320 }}
                className={classNames(styles.imgShadow, styles.desktopOnly)}
              />
            </div>
            {/* Explore*/}
            <span className={classNames(shared.heading2, styles.header)}>
              Going somewhere? Check out cities and areas before you{" "}
              <b>visit</b>
            </span>
            <div className={styles.cityList}>
              {citiesData.map((c) => (
                <DemoCity city={c} />
              ))}
            </div>
            <img
              src={require("../../assets/landing/city_details.png")}
              style={{ maxWidth: 500, marginTop: -10 }}
            />

            <span
              className={classNames(shared.heading4, styles.subHeader)}
              style={{ marginTop: 16 }}
            >
              See the landmarks you visit during your activity
            </span>
            <picture>
              <source
                srcSet={require("../../assets/landing/landing_image_5_mobile.webp")}
                media="(max-width: 600px)"
              />
              <img src={require("../../assets/landing/landing_image_5.webp")} />
            </picture>
            <div className={styles.authBoxMobile}>{this.renderAuthBox()}</div>
          </Content>
          <div className={styles.footer}>
            <Footer />
          </div>
        </IonContent>
      </>
    );
  }
}

function mapStateToProps(state): StateProps {
  return {};
}
function mapDispatchToProps(dispatch): DispatchProps {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
