import React, { CSSProperties } from "react";
import styles from "./Stat.module.css";
import shared from "../../styles/shared.module.css";
import classNames from "classnames";
import CompleteTick from "../completetick/CompleteTick";

function Stat({
  label,
  value,
  style = {},
  valueColor,
  loading,
  complete,
  editable,
}: {
  label: string | number | undefined;
  value: string | number | undefined;
  style?: CSSProperties;
  valueColor?: string;
  loading?: boolean;
  complete?: boolean;
  editable?: boolean; // for easy editing production of marketing marterial
}) {
  return (
    <div className={styles.statContainer} style={style}>
      <div className={styles.statLabel}>{label}</div>
      {loading ? (
        <div className={classNames(shared.shine, styles.statValueLoading)} />
      ) : (
        <>
          <div
            className={classNames(
              shared.heading2,
              complete ? styles.statValueComplete : styles.statValue
            )}
            style={{
              color: complete ? "var(--green)" : valueColor,
            }}
            contentEditable={editable}
          >
            {value}
            {complete ? (
              <CompleteTick size={20} style={{ marginLeft: 8 }} />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(Stat);
