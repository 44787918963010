import React, { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ActivityDescriptionPermissionsPopup.module.css";
import sharedStyles from "../../styles/shared.module.css";
import {
  activityDescriptionPermissionsPopupSelector,
  deleteConfirmPopupSelector,
} from "../../state/popups/PopupsSelectors";
import { hidePopup, PopupTypes } from "../../state/popups/PopupsActions";
import ActivityDescriptionPermissions from "../../components/activity-description-permissions/ActivityDescriptionPermissions";
import classNames from "classnames";
import Button from "@mui/material/Button";
import LangKeys from "../../data/languages/LangKeys";
import {IonButton, isPlatform} from "@ionic/react";
import {useGetSettingsQuery, useUserQuery} from "../../state/api/ApiSlice";
import {useHistory} from "react-router-dom";
import {isApp} from "../../platform/PlatformManager";
import Echo from "../../platform/appplugins/StravaAuthPlugin";

export default function ActivityDescriptionPermissionsPopup() {
  const dispatch = useDispatch();
  const{data: userResponse} = useUserQuery({})
  const popup = useSelector(activityDescriptionPermissionsPopupSelector);
  const hide = useCallback(() => {
    dispatch(
      hidePopup({ popupType: PopupTypes.ACTIVITY_DESCRIPTION_PERMISSIONS })
    );
  }, []);

  const history = useHistory();
  const stravaLogin = async () => {
    const clientId = "43843";
    if (!isApp()) {
      window.location.href = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${window.location.origin}/account-setup/strava&approval_prompt=auto&scope=read,activity:read,activity:read_all,activity:write`;
    }
    if (isPlatform("ios")) {
      console.log("IOS strava auth");
      const url = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=citypainter%3A%2F%2Fcitypainter.io/account-setup/strava&approval_prompt=auto&scope=read,activity:read,activity:read_all,activity:write`;
      // @ts-ignore
      const { value } = await Echo.auth({ value: url });
      const newUrl = "/account-setup/strava?" + value;
      history.push(newUrl);
    }

    if (isPlatform("android")) {
      //const url = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=citypainter%3A%2F%2Fcitypainter.io/account-setup/strava&approval_prompt=auto&scope=read,activity:read`;
      const url = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=citypainter%3A%2F%2Fcitypainter.io/account-setup/strava&approval_prompt=auto&scope=read,activity:read,activity:read_all,activity:write`;
      // @ts-ignore
      Echo.auth({
        value: url,
        clientId,
        redirectUri:
          "&redirect_uri=https://citypainter.io/account-setup/strava",
      });
    }
  };

  const onOKClick = () => {
    if(userResponse?.success) {
      const user = userResponse.user;
      const permissions = userResponse.vendor_permissions;
      if(permissions?.includes("activity:write")) {
        hide();
      } else {
        stravaLogin();
        hide();
      }
    }
  }


  const { data: settings } = useGetSettingsQuery();

  const showStats =
    settings?.success && settings.settings.activity_desc_new_ground;
  const showNeighbourhoods =
    settings?.success && settings.settings.activity_desc_neighbourhoods;
  const showLandmarks =
    settings?.success && settings.settings.activity_desc_landmarks;
  return (
    <Dialog open={popup.visible} onClose={hide}>
      <div className={styles.container}>
        <div className={classNames(sharedStyles.heading4, styles.heading)}>
          Add visited neighbourhoods, landmarks and stats to your Strava
          activity description?
        </div>

        <div>
          <br />
          <div className={sharedStyles.text}>Example:</div>
          <div className={classNames(styles.example, sharedStyles.text)}>
            <span
              className={classNames(styles.item, {
                [styles.active]: showStats,
              })}
            >
              New ground: 11.65 %
            </span>
            <br />
            <br />
            <span
              className={classNames(styles.item, {
                [styles.active]: showNeighbourhoods,
              })}
            >
              🏘New town 🏘️Old Town
            </span>
            <br />
            <br />
            <span
              className={classNames(styles.item, {
                [styles.active]: showLandmarks,
              })}
            >
              📍Old Police Call Box 📍The Manuscript of Monte Cassino
            </span>
          </div>
        </div>
        <ActivityDescriptionPermissions />
        <div className={styles.buttonContainer}>
          <IonButton onClick={onOKClick}>OK</IonButton>
        </div>
      </div>
    </Dialog>
  );
}
